/*
 * File: AssistantButton.tsx
 * Project: meki
 * File Created: Wednesday, 29th December 2021 4:39:13 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 24th November 2022 4:10:32 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useMutation } from '@apollo/client';
import {
  CreateOrderMutation,
  CreateOrderMutationResponse,
} from '@queries/order/mutations';
import React, { useState } from 'react';
import { useToast } from '@hooks/toast.hook';
import { AlertModal } from '@components/Modal';

import { Console } from '@utils';
import { usePermissions } from '@hooks/usePermissions';
import { useOrderState } from '@hooks/useOrderState';
import { useMekiState } from '@hooks/useMekiState';
import { styled } from '@mui/system';
import dynamic from 'next/dynamic';
const FloatingAssistantButton = dynamic(
  () => {
    const promise = import(
      '@components/assistant/FloatingAssistantButton'
    ).then((mod) => mod.FloatingAssistantButton);
    return promise;
  },
  { ssr: false, loading: () => null },
);

const AssistantCreateNewOrderFab = styled(Fab)({
  position: 'fixed',
  right: 15,
  bottom: 70,
});

export function AssistantButton() {
  const { userCan } = usePermissions();
  const toasts = useToast();

  // App state
  const { setOrderUuid } = useOrderState();
  const { setUserEmail } = useMekiState();

  // Local state
  const [newOrderModal, setNewOrderModal] = useState(false);

  // Other vars
  const isAssistant = userCan('MANAGE_USER');
  const [mutate, { loading }] = useMutation<CreateOrderMutationResponse>(
    CreateOrderMutation('assistantCreateOrderHome'),
  );
  if (!isAssistant) return null;
  return (
    <>
      <FloatingAssistantButton />
      <AssistantCreateNewOrderFab
        color="primary"
        variant="extended"
        onClick={() => setNewOrderModal(true)}
        disabled={loading}
      >
        <AddIcon />
        Cotización
      </AssistantCreateNewOrderFab>
      <AlertModal
        title="Nueva cotización ⚠️"
        content={
          'Se creará una nueva cotización vacia y no podrás acceder a la anterior'
        }
        open={newOrderModal}
        setOpen={setNewOrderModal}
        onClose={() => setNewOrderModal(false)}
        actions={[
          {
            text: 'CANCELAR',
            onActionClick: () => setNewOrderModal(false),
          },
          {
            text: 'confirmar',
            onActionClick: async () => {
              setNewOrderModal(false);
              setUserEmail('');
              const {
                data: { createOrder: order },
              } = await mutate();
              Console.log({
                msg: '[Home] change order uuid',
                orderUuid: order.uuid,
              });
              setOrderUuid(order.uuid);
              toasts.showToast({
                message: 'Inicio de nueva cotización',
                type: 'success',
              });
            },
            variant: 'contained',
          },
        ]}
      />
    </>
  );
}
