/*
 * File: header.tsx
 * Project: meki
 * File Created: Wednesday, 9th June 2021 2:24:48 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Saturday, 19th November 2022 7:33:29 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useMemo } from 'react';
import { HomeSearchBox } from './components/searchBoxHome';
import { HomeNavBar } from './components/homeNavBar';
import { isSafari } from '@config/environment';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { Header } from '../../components/Header';
import Image from 'next/image';
import { useWindowSize } from '@hooks/useWindowSize';
import {
  ContentDiv,
  RootDiv,
  ContentContainer,
  AllMedsBox,
  AllMedsInfoDiv,
  AllMedsTypography,
  SemiBold,
  LogoLeft,
  LogoLeftMedium,
  LogoLeftSmall,
  LogoRight,
  LogoRightMedium,
  LogoRightSmall,
  LogoRightPhone,
  SearchBoxDiv,
} from './header.styles';

export function LandingHeader() {
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 768;
  }, [size.width]);
  const focusSearchBox = useFocusSearchBox();
  return (
    <>
      <ContentDiv>
        {focusSearchBox.isFocus ? (
          isSafari || !desktop ? (
            <Header autoFocus={true}></Header>
          ) : (
            <HomeNavBar />
          )
        ) : (
          <HomeNavBar />
        )}
        <RootDiv>
          <ContentContainer>
            <AllMedsBox>
              <AllMedsInfoDiv>
                <AllMedsTypography variant="h1">
                  Medicamentos a <SemiBold>precios bajos</SemiBold> en tu casa
                </AllMedsTypography>
              </AllMedsInfoDiv>
            </AllMedsBox>
          </ContentContainer>
        </RootDiv>
      </ContentDiv>
      <div>
        <LogoLeft>
          <Image
            layout="fill"
            alt="esculapio_bar"
            src={'/assets/images/landing/esculapio_bar.webp'}
            sizes="200px"
          />
        </LogoLeft>
        <LogoLeftMedium>
          <Image
            layout="fill"
            alt="esculapio_bar_medium"
            src={'/assets/images/landing/esculapio_bar_medium.webp'}
            sizes="92px"
          />
        </LogoLeftMedium>
        <LogoLeftSmall>
          <Image
            layout="fill"
            alt="esculapio_bar_small"
            src={'/assets/images/landing/esculapio_bar_small.webp'}
            sizes="74px"
          />
        </LogoLeftSmall>
        <LogoRight>
          <Image
            layout="fill"
            alt="meki_bag_with_pills"
            src={'/assets/images/landing/meki_bag_with_pills.webp'}
            sizes="220px"
            priority
          />
        </LogoRight>
        <LogoRightMedium>
          <Image
            layout="fill"
            alt="meki_bag_with_pills_medium"
            src={'/assets/images/landing/meki_bag_with_pills_medium.webp'}
            sizes="102px"
            priority
          />
        </LogoRightMedium>
        <LogoRightSmall>
          <Image
            layout="fill"
            alt="meki_bag_with_pills_small"
            src={'/assets/images/landing/meki_bag_with_pills_small.webp'}
            sizes="61px"
            priority
          />
        </LogoRightSmall>
        <LogoRightPhone>
          <Image
            layout="fill"
            alt="meki_bag_with_pills_small"
            src={'/assets/images/landing/meki_bag_with_pills_phone.webp'}
            sizes="118.5px"
            priority
          />
        </LogoRightPhone>
      </div>
      {focusSearchBox.isFocus ? (
        isSafari || !desktop ? (
          <></>
        ) : (
          <HomeSearchBox></HomeSearchBox>
        )
      ) : (
        <HomeSearchBox></HomeSearchBox>
      )}
      <SearchBoxDiv></SearchBoxDiv>
    </>
  );
}
