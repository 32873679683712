/*
 * File: homeNavBar.tsx
 * Project: meki
 * File Created: Thursday, 28th July 2022 4:01:03 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Thursday, 20th July 2023 10:48:31 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  RootDiv,
  HeaderAppBar,
  HeaderToolbar,
  HeaderLeftDiv,
  PositionDivLeft,
  PositionDivRight,
  BackIconButton,
  HeaderRightComponent,
  LoginButton,
  MekiLogoDiv,
} from './homeNavBar.styles';
import { DrawerMenu } from '@screens/menu';
import { MekiLogo } from '@components/icons/LogoMeki';
import { useRouter } from 'next/router';
import { useModalRouter } from '@hooks/useModalRouter';
import { getRoute, removeUndefineds } from '@utils';
import { useAuth } from '@hooks/useAuth';
import { useWindowSize } from '@hooks/useWindowSize';
import { DrawerCart } from '@screens/cart/DrawerCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { useScroll } from '@hooks/useScroll';

interface HomeNavBarProps {
  barChangeStart?: boolean | false;
}

export function HomeNavBar(props: HomeNavBarProps) {
  const { barChangeStart } = props;
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 768;
  }, [size.width]);
  const [barChange, setBarChange] = useState(barChangeStart);

  useEffect(() => {
    setBarChange(barChangeStart);
  }, [barChangeStart]);

  const { appUser: user } = useAuth();
  const router = useRouter();
  const focusSearchBox = useFocusSearchBox();
  const modalRouter = useModalRouter();
  const [hideInfo, setHideInfo] = useState(false);
  const scrollYHide = 165;
  const [atTop, setAtTop] = useState(
    typeof window !== 'undefined' ? window.scrollY === 0 : true,
  );
  const atTopRef = useRef(
    typeof window !== 'undefined' ? window.scrollY === 0 : true,
  );
  const scrollStatus = useScroll();
  useEffect(() => {
    const handleScroll = () => {
      const scroll = Math.max(window.scrollY, 0);
      if (!scrollStatus.isScrollAvailable) {
        return;
      }
      if (scroll === 0 && !atTopRef.current) {
        setAtTop(true);
        setBarChange(false);
      }
      if (scroll > 0 && atTopRef.current) {
        setAtTop(false);
        setBarChange(true);
      }
      if (size.width < 530 && size.width > 360) {
        if (scroll > scrollYHide) {
          setHideInfo(true);
        } else {
          setHideInfo(false);
        }
      }

      atTopRef.current = scroll === 0;
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [size.width, scrollStatus.isScrollAvailable]);

  const handleClose = useCallback(() => {
    focusSearchBox.onblur();
  }, [focusSearchBox]);

  const elevation = !atTop ? 1 : 0;
  return (
    <RootDiv>
      <HeaderAppBar
        position="fixed"
        color="primary"
        barChange={barChange}
        elevation={elevation}
      >
        <HeaderToolbar>
          <PositionDivLeft desktop={desktop}>
            <HeaderLeftDiv>
              {!desktop && focusSearchBox.isFocus ? (
                <BackIconButton
                  onClick={handleClose}
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  barChange={barChange}
                  size="large"
                >
                  <ArrowBackIcon />
                </BackIconButton>
              ) : (
                <DrawerMenu menuIconColorChange={barChange}></DrawerMenu>
              )}
              {!hideInfo && (
                <MekiLogoDiv>
                  <MekiLogo
                    fill={barChange ? '#000000de' : 'white'}
                    height="16px"
                    width="auto"
                    onClick={() => {
                      void router.push(getRoute('home'));
                    }}
                  />
                </MekiLogoDiv>
              )}
            </HeaderLeftDiv>
          </PositionDivLeft>
          <PositionDivRight desktop={desktop}>
            <HeaderRightComponent>
              {!hideInfo && (
                <>
                  {!user ? (
                    <LoginButton
                      barChange={barChange}
                      size="medium"
                      onClick={() => {
                        void router.push(
                          {
                            pathname: router.pathname,
                            query: removeUndefineds({
                              ...router.query,
                              pathname: '/ingresar',
                              codigo: undefined,
                            }),
                          },
                          {
                            pathname: '/ingresar',
                            query: removeUndefineds({
                              ...modalRouter.query,
                              codigo: undefined,
                            }),
                          },
                        );
                      }}
                    >
                      {size.width >= 1110 ? 'Iniciar sesión' : 'Inicia'}
                    </LoginButton>
                  ) : (
                    <div>
                      <DrawerMenu
                        barChange={barChange}
                        isFromMenuName={`${user.name?.split(' ')[0]} ${
                          user.lastNames?.[0]?.toLocaleUpperCase() ?? ''
                        }.`}
                      ></DrawerMenu>
                    </div>
                  )}
                </>
              )}
              <DrawerCart barChange={barChange} />
            </HeaderRightComponent>
          </PositionDivRight>
        </HeaderToolbar>
      </HeaderAppBar>
    </RootDiv>
  );
}
