/*
 * File: searchBar.tsx
 * Project: meki
 * File Created: Wednesday, 27th July 2022 11:47:02 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 15th November 2022 12:45:13 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useState, useEffect } from 'react';
import { SearchBox } from '../../../components/searchBox';
import { useWindowSize } from '@hooks/useWindowSize';
import { useScroll } from '@hooks/useScroll';
export function HomeSearchBox() {
  const [searchBoxWidth, setSearchBoxWidth] = useState(320);
  const [searchBoxHeight, setSearchBoxHeight] = useState(56);
  const [searchBoxPadding, setSearchBoxPadding] = useState(16.5);
  const [changeBackgroundColor, setChangeBackgroundColor] = useState(false);
  const scrollYChangeBackgroundColor = 165;
  const scrollYStartResize = 50;
  const scrollYStopResize = 224;
  const maxBoxHeight = 56;
  const minBoxHeight = 38;
  const placeHolderHeight = 23;
  const size = useWindowSize();
  const scrollStatus = useScroll();

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollStatus.isScrollAvailable) {
        return;
      }
      let minBoxWidth;
      let maxBoxWidth;
      if (size.width <= 340) {
        minBoxWidth = 235;
        maxBoxWidth = 300;
      } else {
        minBoxWidth = 262;
        maxBoxWidth = Math.min(500, size.width - 40);
      }
      const scroll = Math.max(window.scrollY, 0);
      if (scroll < scrollYStartResize) {
        setSearchBoxWidth(maxBoxWidth);
        setSearchBoxHeight(maxBoxHeight);
        setSearchBoxPadding((maxBoxHeight - placeHolderHeight) / 2);
      } else if (scroll >= scrollYStartResize && scroll < scrollYStopResize) {
        const widthSlope =
          (maxBoxWidth - minBoxWidth) /
          (scrollYStopResize - scrollYStartResize);
        const newWidth =
          maxBoxWidth - (scroll - scrollYStartResize) * widthSlope;
        const heighSlope =
          (maxBoxHeight - minBoxHeight) /
          (scrollYStopResize - scrollYStartResize);
        const newHeight =
          maxBoxHeight - (scroll - scrollYStartResize) * heighSlope;
        setSearchBoxWidth(newWidth);
        setSearchBoxHeight(newHeight);
        setSearchBoxPadding((newHeight - placeHolderHeight) / 2);
      } else {
        setSearchBoxWidth(minBoxWidth);
        setSearchBoxHeight(minBoxHeight);
        setSearchBoxPadding((minBoxHeight - placeHolderHeight) / 2);
      }
      if (scroll < scrollYChangeBackgroundColor) {
        setChangeBackgroundColor(false);
      } else {
        setChangeBackgroundColor(true);
      }
    };
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [size.width, scrollStatus.isScrollAvailable]);

  return (
    <SearchBox
      searchBoxWidth={searchBoxWidth}
      searchBoxHeight={searchBoxHeight}
      searchBoxPadding={searchBoxPadding}
      changeBackgroundColor={changeBackgroundColor}
      autoScroll={true}
    ></SearchBox>
  );
}
