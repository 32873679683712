/*
 * File: header.styles.tsx
 * Project: meki
 * File Created: Monday, 14th November 2022 1:06:54 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Monday, 14th November 2022 5:34:39 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { styled } from '@mui/system';
import { Typography, Container, Box } from '@mui/material';

const ContentDiv = styled('div')({
  background: '#004C8C',
});

const RootDiv = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: 64,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 1260,
  paddingBottom: 28,
  '@media (max-width: 650px)': {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 800,
  },
  background: '#004C8C',
}));

const ContentContainer = styled(Container)({
  color: '#fff',
  marginBottom: '15px',
});

const AllMedsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  paddingTop: 37,
  '@media (max-width: 768px)': {
    maxWidth: 500,
    margin: 'auto',
  },
  '@media (max-width: 499px)': {
    justifyContent: 'left',
  },
});

const AllMedsInfoDiv = styled('div')({
  alignSelf: 'center',
  '@media (max-width: 499px)': {
    paddingLeft: '5px',
  },
});

const AllMedsTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 26,
  width: 208,
  lineHeight: '34px',
  textAlign: 'left',
  letterSpacing: '-0.419355px',
  [theme.breakpoints.up(400)]: {
    fontSize: '30px',
    width: '230px',
  },
  [theme.breakpoints.up(500)]: {
    fontSize: '30px',
    width: '450px',
    lineHeight: '34px',
    textAlign: 'center',
    letterSpacing: '-0.419355px',
  },
  [theme.breakpoints.up(768)]: {
    marginTop: 0,
    fontSize: '44px',
    width: '603px',
    lineHeight: '66px',
    textAlign: 'center',
    letterSpacing: '-0.709677px',
  },
  [theme.breakpoints.up(1024)]: {
    marginTop: 0,
    marginRight: 0,
    fontSize: '60px',
    width: '799px',
    lineHeight: 1.2,
    textAlign: 'center',
    letterSpacing: '-0.709677px',
  },
}));

const SemiBold = styled('b')({
  fontWeight: 600,
});

const LogoLeft = styled('div')({
  position: 'absolute',
  width: '160px',
  height: '220px',
  left: 0,
  top: 40,
  overflow: 'hidden',
  '@media (min-width: 1070px)': {
    width: '200px',
    height: '270.84px',
  },
  '@media (max-width: 1023px)': {
    display: 'none',
  },
});

const LogoLeftMedium = styled('div')({
  position: 'absolute',
  width: '92px',
  height: '340px',
  left: 0,
  top: 18,
  overflow: 'hidden',
  '@media (max-width: 767px)': {
    display: 'none',
  },
  '@media (min-width: 1024px)': {
    display: 'none',
  },
});

const LogoLeftSmall = styled('div')({
  position: 'absolute',
  width: '74px',
  height: '156px',
  left: 0,
  top: 40,
  overflow: 'hidden',
  '@media (min-width: 768px)': {
    display: 'none',
  },
  '@media (max-width: 499px)': {
    display: 'none',
  },
});
const LogoRight = styled('div')({
  position: 'absolute',
  width: '100px',
  height: '250px',
  right: 0,
  top: 62,
  overflow: 'hidden',
  '@media (min-width: 1070px)': {
    width: '150px',
    height: '350px',
  },
  '@media (min-width: 1170px)': {
    width: '220px',
    height: '600px',
  },
  '@media (max-width: 1023px)': {
    display: 'none',
  },
  zIndex: '1',
});
const LogoRightMedium = styled('div')({
  position: 'absolute',
  width: '102px',
  height: '300px',
  right: 0,
  top: 70,
  overflow: 'hidden',
  '@media (max-width: 767px)': {
    display: 'none',
  },
  '@media (min-width: 1024px)': {
    display: 'none',
  },
});
const LogoRightSmall = styled('div')({
  position: 'absolute',
  width: '61px',
  height: '175.91px',
  right: 0,
  top: 108,
  overflow: 'hidden',
  '@media (min-width: 768px)': {
    display: 'none',
  },
  '@media (max-width: 499px)': {
    display: 'none',
  },
});
const LogoRightPhone = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '118.5px',
  height: '175.91px',
  right: 0,
  top: 108,
  overflow: 'hidden',
  '@media (min-width: 500px)': {
    display: 'none',
  },
  [theme.breakpoints.down(350)]: {
    display: 'none',
  },
}));

const SearchBoxDiv = styled('div')({
  marginTop: -1,
  height: 92,
  background: '#004C8C',
});

export {
  ContentDiv,
  RootDiv,
  ContentContainer,
  AllMedsBox,
  AllMedsInfoDiv,
  AllMedsTypography,
  SemiBold,
  LogoLeft,
  LogoLeftMedium,
  LogoLeftSmall,
  LogoRight,
  LogoRightMedium,
  LogoRightSmall,
  LogoRightPhone,
  SearchBoxDiv,
};
