import { useEffect } from 'react';
import { LandingHeader } from './header';
import { Footer } from './footer';
import { useSegment } from '@hooks/useSegment';
import { useAuth } from '@hooks/useAuth';
import {
  BannerSlideSection,
  TransparencySection,
  CommentsSection,
  CenabastSection,
  WorkWithSection,
  FaqSection,
  ISPBanner,
  DeliveryZones,
  HomeCategories,
  ReferralBannerDiscount,
} from './logged-out-sections';
import { AssistantButton } from './components/AssistantButton';
import { getRoute, removeUndefineds } from '@utils';
import { useRouter } from 'next/router';
import { useMekiState } from '@hooks/useMekiState';
import { useQuery } from '@apollo/client';
import { useOpenModal } from '@hooks/useModalOpenState';
import { useFacebokPixelEvent } from '@hooks/useFacebookPixel';
import {
  ValidateReferenceCouponParams,
  ValidateReferenceCouponQuerie,
  ValidateReferenceCouponResponse,
} from '@queries/referenceCoupon/queries';

export function LandingMain() {
  const { track } = useSegment();
  const { appUser: user } = useAuth();
  const { isModalOpen } = useOpenModal();
  const router = useRouter();
  const { codigo: code } = router.query;
  const { setReferralCode } = useMekiState();
  const { trackFBEvent } = useFacebokPixelEvent();
  const codeString = code ? decodeURI(code as string) : '';

  const { data: { validateReferenceCoupon: referralUser } = {} } = useQuery<
    ValidateReferenceCouponResponse,
    ValidateReferenceCouponParams
  >(ValidateReferenceCouponQuerie(), {
    variables: { params: { code: codeString.replace(/\s.*/g, '') } },
    fetchPolicy: 'cache-first',
    skip: !codeString.replace(/\s.*/g, ''),
  });

  useEffect(
    function sanitizeReferalCode() {
      if (
        referralUser?.type == 'referral' &&
        router.query?.codigo?.length > 6
      ) {
        const routerCode = (router.query?.codigo as string).replace(
          /\s.*/g,
          '',
        );
        void router.replace(
          {
            pathname: router.pathname,
            query: removeUndefineds({
              ...router.query,
              codigo: routerCode,
            }),
          },
          {
            pathname: router.asPath.replace(/\?.*/, ''),
            query: removeUndefineds({
              ...router.query,
              codigo: routerCode,
            }),
          },
        );
      }
    },
    [referralUser?.type, router],
  );
  useEffect(
    function trackLandingView() {
      void track('landing view', {
        logged: user ? 'true' : 'false',
        referral_link: code,
        userId: user ? user.id : 'undefined',
      });
      trackFBEvent('track', 'ViewContent', {});
    },
    [track, user, code, trackFBEvent],
  );
  useEffect(() => {
    if (code && referralUser?.isValid) {
      setReferralCode(referralUser.code);
    }
  }, [code, setReferralCode, referralUser]);

  useEffect(() => {
    if (user && !isModalOpen) {
      void router.replace(getRoute('homeLoggedIn'));
    }
  }, [user, isModalOpen, router]);
  return (
    <>
      <LandingHeader />
      <HomeCategories sliderItemVersion={'version1'} />
      <DeliveryZones />
      <ISPBanner />
      <TransparencySection />
      <CommentsSection />
      <CenabastSection />
      <WorkWithSection />
      <BannerSlideSection />
      <FaqSection />
      <Footer />
      <AssistantButton />
      {referralUser?.isValid && (
        <ReferralBannerDiscount
          name={referralUser.name ?? referralUser.code}
          lastName={referralUser.lastName ?? ''}
          amount={referralUser.amount ?? 5000}
        />
      )}
    </>
  );
}
