/*
 * File: createOrder.tsx
 * Project: meki
 * File Created: Wednesday, 4th November 2020 11:08:37 am
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Thursday, 29th April 2021 12:46:47 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { Order } from '@interfaces';

export const CreateOrderMutation = (name: string) => gql`
  mutation ${name} {
    createOrder {
      id
      uuid
      orderDetails {
        id
      }
    }
  }
`;

export type CreateOrderMutationResponse = { createOrder: Order };
